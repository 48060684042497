import createAuthService from "../../common/auth/auth-service";

export default function SignoutCallback() {
  if (typeof window === "undefined") return null;
  const authService = createAuthService();
  authService.signoutRedirect().then(() => {
    authService
      .login()
      .then(() => {})
      .catch(ex => console.log(ex));
  });

  return null;
}
